"use strict";



const local ='http://localhost:3492'
const cpanel ='https://geabariatric.com/' // cambiar por la url del sitio web.

exports.url  =  cpanel + '/api/Ncl_q3O/'; 

const paises =[
    'Estados Unidos'
    ,'México',
    'Otro',
]
exports.paises = paises;

//En línea