<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },

  async created() {
    await this.getAllInfoClr("profile");

    this.colores = this.profiles;
    this.actualizarColores();
  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
};
</script>

<style>
@import "./css/default.css";

@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Sora:wght@100;200;300;400;500;600;700;800&family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap');

html {
  scroll-behavior: smooth;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #e21f1d;
  --color-2: #242424;
  --color-3: #a7a7a7;
  --color-4: #f5f5f5;
  --color-5: #ffffff;
  --color-6: #d9d9d9;
  --color-7: #ffffff;
  --color-8: #59c1af;
  --color-9: #787878;
  --color-10: #2b4a88;
  --color-11: #99ded2;
  --color-12: #4678ba;

  --Sans: "Source Sans Pro", sans-serif;
  --Open_Sans: "Open Sans", sans-serif;
  --Sora: "Sora", sans-serif;
  --Mukta: "Mukta", sans-serif;
  --dmsans: "DM Sans", sans-serif;
  --montserrat: "Montserrat", sans-serif;
  --inter: "Inter", sans-serif;

  --redHatDisplay: "Red Hat Display", sans-serif;
  --Lato: 'Lato', sans-serif;

  --fs-idseccionpc: 0.625vw;

  /* Tipografias */
  --font-titulo1: "Outfit";
  --font-titulo2: "Outfit";
  --font-parrafo: "Outfit";
  --font-parrafo2: "Outfit";

  overscroll-behavior: none;
}
.select_status{
  z-index: 1;
}
.max_hg{
  max-height: 80vh !important;
}
.wd_sharing{
  width: 20% !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.algc {
  align-items: center;
}

.mtp {
  margin-top: 1.5vw
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
  overflow: hidden;
}

/* Cancelar cita */
input.cancelacion_input {
  border: 1px solid #D9D9D9;
  width: 19.3vw;
  padding: 0.5vw;
  margin-bottom: .5vw;
}

p.motivo_cancelacion {
  color: #747474;
  font-size: .7vw;
  margin-bottom: .8vw;
  font-family: Red Hat Display;
}

.info_can {
  display: flex;
  align-items: center;
}

.info_can p {
  margin: 0vw;
}

.info_can p:first-child {
  width: .7vw;
  height: .7vw;
  margin-right: .3vw;
  background-color: #ffa084;
}

.info_can p:last-child {
  color: #747474;
  font-size: .8vw;
  font-family: Red Hat Display;
}

.bg_cancel .name-wrapper {
  background-color: #ffa084 !important;
}

.bg_cancel p {
  background-color: #ffa084 !important;
  color: white !important;
}

.bg_cancel_dv {
  background-color: #ffa084 !important;
}

.bg_cancel_dv_pc {
  background-color: #ffa084 !important;
}

.bg_cancel_dv p {
  color: white !important;
}

.bg_cancel_dv_pc p {
  color: white !important;
}

p#btn_cancelada {
  width: fit-content;
  color: white;
  padding: .5vw;
  margin-bottom: .5vw;
  margin-top: .5vw;
}


/*INICIO SLIDE CAROUSEL TESTIMONIOS*/

.slideA .VueCarousel-dot-container {
  display: flex !important;
  justify-content: center;
  /* width: 47vw; */
  margin: 0 auto;
  padding: 0;
}

.slideA .VueCarousel-dot {
  width: 6.763285024154589vw !important;
  height: 6.763285024154589vw !important;
  transition: width 0.3s ease-in;
}

.slideA button.VueCarousel-dot.VueCarousel-dot--active {
  /*width: 100% !important;*/
  background-color: #4678ba !important;
  outline: 0px !important;
  border-radius: 10vw !important;
  width: 21.191545893719805VW !important;
}

.slideA .VueCarousel-dot:focus {
  /*background-color: #4678BA !important;*/
  outline: 0px solid lightblue !important;
}

@media (min-width: 769px) {

  .ver_documento a {
    font-size: .8vw;
  }

  html {
    scroll-padding-top: 6.041666666666667vw;
  }

  .ficha .info .exp_i .itm>p span {
    color: #fff !important;
    font-size: 0.9375VW;
    line-height: 122.727%;
  }

  /* .slideA .VueCarousel-dot-container {
    width: 6.458333333333334vw; 
  }*/

  .slideA .VueCarousel-dot {
    width: 0.8348958333333333vw !important;
    height: 0.8348958333333333vw !important;
  }

  .slideA button.VueCarousel-dot.VueCarousel-dot--active {
    width: 2.0625VW !important;

  }
}

/*FIN SLIDE CAROUSEL TESTIMONIOS*/

/*INICIO SLIDE CAROUSEL BLOG*/
.slideB .VueCarousel-inner {
  width: 52vw;
}

.slideB .VueCarousel-slide {
  /* flex-basis: inherit; */
  /* backface-visibility: hidden; */
  width: 52vw;
}

.doctor__descripcion p,
.cuadros__cuerpo p {
  color: #787878;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.cuadros__cuerpo strong {
  font-weight: 700;
  font-family: Red Hat Display;
  font-style: normal;
}

.ver_documento a {
  text-decoration: underline;
  font-weight: 700;
  color: #2B4A88 !important;
  font-family: 'Red Hat Display';
}

@media(max-width: 768px) {

  .traslate_view{
    transform: translate(0px, 0px);
  }


  .ver_documento a {
    font-size: 4.8vw;
  }

  div[role = dialog] {
    z-index: 1000;
  }

  .colaborador__info strong p {
    margin: 0;
    color: var(--Texto, #787878);
    font-family: var(--redHatDisplay);
    font-size: 2.898550724637681VW;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }


  .doctor__descripcion p,
  .cuadros__cuerpo p {
    font-size: 14px;
    line-height: 214.286%;
  }

  .cuadros__cuerpo strong {
    color: #787878 !important;
  }
}

@media (min-width: 769px) {

  .colaborador__info strong p {
    margin: 0;
    color: var(--Texto, #787878);
    font-family: var(--redHatDisplay);
    font-size: 0.625VW;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .doctor__descripcion p,
  .cuadros__cuerpo p {
    font-size: 0.625VW;
    line-height: 191.667%;
  }

  .cuadros__cuerpo strong {
    color: #4678BA !important;
  }

  .slideB .VueCarousel-inner {
    width: 44vw;
  }

  .slideB .VueCarousel-slide {
    width: 44vw;
  }
}

/*FIN SLIDE CAROUSEL BLOG*/

/*INICIO SLIDE CAROUSEL EQUIPO*/

.slideC .VueCarousel-dot-container {
  display: flex !important;
  justify-content: center;
  width: 47vw;
  margin: 0 auto;
  padding: 0;
  margin-top: 0vw !important;
}

.slideC .VueCarousel-dot {
  width: 6.763285024154589vw !important;
  height: 6.763285024154589vw !important;
  margin-top: 0vw !important;
}

.slideC button.VueCarousel-dot.VueCarousel-dot--active {
  background-color: #4678ba !important;
  outline: 0px !important;
  border-radius: 10vw !important;
}

.slideC .VueCarousel-dot:focus {
  /*background-color: #4678BA !important;*/
  outline: 0px solid lightblue !important;
}

.slideC .VueCarousel-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 8vw;
}

@media (min-width: 769px) {
  .slideC .VueCarousel-pagination {
    width: fit-content !important;
    margin-left: 96%;
    margin-top: 22vw;
    position: absolute;
  }

  .slideC .VueCarousel-dot-container {
    width: 6.458333333333334vw;
    flex-flow: column;
  }

  .slideC .VueCarousel-dot {
    width: 0.8348958333333333vw !important;
    height: 0.8348958333333333vw !important;
  }

  .slideC .VueCarousel {
    flex-direction: row;
  }

  .slideC .VueCarousel-wrapper {
    padding-bottom: 0vw;
  }
}

/*FIN SLIDE CAROUSEL EQUIPO*/

body {
  margin: 0vw;
}

html {
  scroll-behavior: smooth;
}

.wd6 {
  width: 7.4vw;
}

.ver_documento {
  margin-top: 2vw;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

.dflx {
  display: flex;
}

.dflxc {
  display: flex;
  justify-content: center;
}

.dflxr {
  display: flex;
  justify-content: right;
}

.tac {
  text-align: center !important;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.jcsev {
  justify-content: space-evenly;
}

.jcse {
  justify-content: flex-end;
}

.mla {
  margin: 0 0 0 auto;
}

.mlc {
  margin: 0 auto;
}

.aic {
  align-items: center;
}

.box_ctr {
  display: flex;
  justify-content: center;
}

.box_rh {
  display: flex;
  justify-content: right;
}

.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crs_ptr {
  cursor: pointer;
}

body::-webkit-scrollbar,
.nta_bd::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical,
.nta_bd::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #f4f4f4;
  border-radius: 1.0416666666666665vw;
}

body::-webkit-scrollbar-button:increment,
body::-webkit-scrollbar-button,
.nta_bd::-webkit-scrollbar-button:increment,
.nta_bd::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar:horizontal,
.nta_bd::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

body::-webkit-scrollbar-thumb,
.nta_bd::-webkit-scrollbar-thumb {
  background-color: #CCAE62;
  border-radius: 1.0416666666666665vw;
  border: 0.10416666666666667vw solid #CCAE62;
}

body::-webkit-scrollbar-track,
.nta_bd::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

p.cp {
  cursor: pointer !important;
}

.mdl_btn_add p img {
  height: .8vw;
}

p.btn_ag {
  border-radius: 5.208vw;
  background: #88D3A2;
  display: flex;
  width: 5.208vw;
  height: 1.458vw;
  justify-content: center;
  align-items: center;

  color: #FFF;
  text-align: center;
  font-family: 'Red Hat Display';
  font-size: 0.625vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.box_videollamadas {
  margin-top: 1vw;
  max-height: 13vw;
  width: 90%;
  overflow: auto;
}

.btns_lider {
  display: flex;
  align-items: center;
}

.btns_lider p {
  border-radius: 5.260vw;
  background: #787878;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 7vw;
  height: 2vw;

  margin-right: 1vw;
  cursor: pointer;
  color: #FFF;

  font-family: 'Red Hat Display';
  font-size: 0.729vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: all 200ms;
}

.btns_lider p.act_ld {
  background: #4A8CFF !important;
}


.btn_videollamada {
  border-radius: 0.260vw;
  background: #4A8CFF;
  display: flex;
  width: 13.958vw;
  height: 2.396vw;
  justify-content: center;
  align-items: center;
  margin-top: 0.990vw;
  cursor: pointer;
  margin-bottom: 1vw;
}

.btn_videollamada img {
  margin-right: 0.521vw;
  width: 1.406vw;
  height: 1.406vw;
}

.F1 span {
  color: #CCAE62 !important;
}

.btn_videollamada p {
  color: #FFF;
  text-align: center;
  font-family: 'Red Hat Display';
  font-size: 0.729vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
}

.colaborador__info strong p {
  color: rgba(255, 255, 255, 0.722) !important;
}

.colaborador__info>p {
  color: rgba(255, 255, 255, 0.722) !important;
}

.ficha .header-f p span {
  color: #CCAE62 !important;
}

.certc .tl {
  color: #CCAE62 !important;
}

.certc .tl span {
  color: #CCAE62 !important;
}

.showimp {
  display: flex !important;
}

.box_videollamadas::-webkit-scrollbar {
  -webkit-appearance: none;
}

.box_videollamadas::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #f4f4f4;
  border-radius: 1.0416666666666665vw;
}

.box_videollamadas::-webkit-scrollbar-button:increment,
.box_videollamadas::-webkit-scrollbar-button {
  display: none;
}

.box_videollamadas::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

.box_videollamadas::-webkit-scrollbar-thumb {
  background-color: #99ded2;
  border-radius: 1.0416666666666665vw;
  border: 0.10416666666666667vw solid #99ded2;
}

.box_videollamadas::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.zmwebsdk-makeStyles-draggableDivider-225 {
  display: none !important;
}

.colaborador__info>h4 {
  color: white !important;

}


.text_descr {
  color: var(--Texto, #787878);
  font-family: 'Red Hat Display';
  font-size: 0.625vw;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  /* 191.667% */
}

@media (min-width: 768px) {}

@media (max-width: 768px) {
  .item__cancel--mov {
    background: linear-gradient(to right, #ffa084 0%, #ffa084 2.898550724637681VW, white 2.898550724637681VW, white 100%) !important
  }

  .bg_cancel_dv_pc {
    background-color: transparent !important;
  }

  .bg_cancel_dv_pc p {
    color: #2B4A88 !important;
  }

  .text_descr {
    font-size: 3.382vw;
  }

  .btn_videollamada p {
    font-size: 2.729vw;
  }

  .btn_videollamada {
    border-radius: 1.26vw;
    background: #4A8CFF;
    display: flex;
    width: 63.958vw;
    height: 9.396vw;

    margin-top: 2.99vw;
    margin-bottom: 2vw;
    display: none;
  }

  .box_videollamadas {
    margin-top: 1vw;
    max-height: 68vw;
    width: 90%;
    overflow: auto;
  }

  .btn_videollamada img {
    margin-right: 2.521vw;
    width: 5.406vw;
    height: 5.406vw;
  }

  p.linkzoom {
    color: #4A8CFF !important;
    text-decoration: underline;
  }



}
</style>
