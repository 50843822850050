import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import logo from './modules/logo'
import main from './modules/main'
import profile from './modules/profile'
import usuarios from './modules/usuarios'
import suscripciones from './modules/suscripciones'
import blog from './modules/blog'
import blog_comment from './modules/blog_comment'
import blog_category from './modules/blog_category'
import category from './modules/category'
import colaboradores from './modules/colaboradores'
import tag from './modules/tag'
import patient from './modules/patient'
import home_menu from './modules/home_menu'
import doctor from './modules/doctor'
import address from './modules/address'
import phone from './modules/phone'
import horario from './modules/horario'
import social_media from './modules/social_media'
import dashboard from './modules/dashboard'
import agenda from './modules/agenda'
import cuestionario from './modules/cuestionario'
import copy from './modules/copy'
import doctores from './modules/doctores'
import citas from './modules/citas'
import archivos from './modules/archivos'
import recordatorios from './modules/recordatorios'
import pasarela from './modules/pasarela'
import estudios from './modules/estudios'
import doctorinf from './modules/doctorinf'
import bloqueo_citas from './modules/bloqueo_citas'
import canceled_appointment from './modules/canceled_appointment'
import revision_appointment from './modules/revision_appointment'
import agenda_nota_status from './modules/agenda_nota_status'
import meeting from './modules/meeting'
import historial from './modules/historial'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    main: main,
    usuarios: usuarios,
    profile:profile,
    colors:colors,
    logo:logo,
    suscripciones:suscripciones,
    blog: blog,
    blog_comment: blog_comment,
    blog_category: blog_category,
    category: category,
    colaboradores: colaboradores,
    tag: tag,
    patient:patient,
    doctor:doctor,
    address:address,
    phone:phone,
    horario:horario,
    social_media:social_media,
    home_menu: home_menu,
    dashboard:dashboard,
    agenda:agenda,
    cuestionario:cuestionario,
    copy:copy,
    doctores:doctores,
    citas:citas,
    archivos:archivos,
    recordatorios:recordatorios,
    pasarela:pasarela,
    estudios:estudios,
    doctorinf:doctorinf,
    bloqueo_citas: bloqueo_citas,
    canceled_appointment: canceled_appointment,
    revision_appointment: revision_appointment,
    agenda_nota_status: agenda_nota_status,
    meeting: meeting,
    historial:historial,
  }
})
