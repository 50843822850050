'use strict'

import api from '@/api/api';
import userService from '../../services/user.service'
import ncryptService from '../../services/ncrypt.service'

const GETTING_INFO      = 'GET_INFO',
      GET_INFO_SUCCESS  = 'GET_INFO_SUCCESS',
      SET_HISTORY_OPTION = 'SET_HISTORY_OPTION',
      SET_ADDED = 'SET_ADDED';

const ERR_OBJ = {
    status:'error',
    message:'Ha ocurrido un error al intentar obtener la información.',
    result:''
}  

export default {
    namespaced: true,
    state:{
        loading: false,
        data: [],
        historyOption: 'Default',
        added:''
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        getHistoryOption(state){
            return state.historyOption
        },
        getAdded(state){
            return state.added
        },
      
    },
    mutations: {
        [GETTING_INFO](state) {
            state.loading = true;
            state.data = [];
        },
        [GET_INFO_SUCCESS](state, change) {
            state.loading = false;
            state.data = change;
        },
        [SET_HISTORY_OPTION](state, change){
            state.historyOption = change;
        },
        [SET_ADDED](state, change){
            state.added = change;
        },
    },
    actions:{
        setAddedMtn({commit}, payload){
            commit(SET_ADDED, payload);  
        },

        setHistoryOptionMtn({commit}, payload){
            commit(SET_HISTORY_OPTION, payload);  
        },
        async getAllInfoMtn({ commit }, payload) {
            commit(GETTING_INFO);
            try {
                const token = userService.getToken() 
                const _pk = ncryptService._createPubToken()
                const headers =  {'Authorization': token, "_pk":_pk} ;
                const response = await api.getAllInfo(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                return response.data;
            } catch (error) {
                return ERR_OBJ
            }
        },

        async getInfoByIdMtn({ commit }, payload) {
            commit(GETTING_INFO);
            try {              
                const token = userService.getToken() 
                const _pk = ncryptService._createPubToken()
                const headers =  {'Authorization': token, "_pk":_pk} ;
                const response = await api.getInfoById(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                return response.data.result;
            } catch (error) {
                return ERR_OBJ
            }
        },

        async deleteItemMtn({ commit }, payload) { 
            
            try {               
                const token = userService.getToken() 
                const _pk = ncryptService._createPubToken()
                const headers =  {'Authorization': token, "_pk":_pk} ;
                const response = await api.deleteItem(payload, headers);
                return response.data;
            } catch (error) {
                return ERR_OBJ
            }
        },
        async editItemMtn({ commit }, payload) { 
            
            try {               
                const token = userService.getToken() 
                const _pk = ncryptService._createPubToken()
                const headers =  {'Authorization': token, "_pk":_pk} ;
                const response = await api.editItem(payload, headers);
                return response.data;
            } catch (error) {
                return ERR_OBJ
            }
           
        },

        async addItemMtn({ commit }, payload) { 
            try {               
                const token = userService.getToken() 
                const _pk = ncryptService._createPubToken()
                const headers =  {'Authorization': token, "_pk":_pk} ;
                const response = await api.addItem(payload, headers);
                return response.data;
            } catch (error) {
                return ERR_OBJ
            }
           
        }
   
    },
}